import {isMobile} from "./functions";

if (document.querySelector('#map') !== null) {
    ymaps.ready(init);

    function init() {

        let myMap = new ymaps.Map('map', {
                center: [55.758038, 37.572225],
                zoom: 16,
                controls: ['zoomControl']
            }),

            myPlacemark1 = new ymaps.Placemark([55.758038, 37.572225], {
                // balloonContent: 'Маленькая иконка'
            }, {
                iconLayout: 'default#image',
            });

        myMap.geoObjects.add(myPlacemark1);

        myMap.behaviors.disable('scrollZoom');
        if (isMobile) {
            myMap.setZoom(16);
            myMap.behaviors.disable('drag');
        }

        function setCenterMap() {
            if (window.matchMedia('(max-width: 767px)').matches) {
                myMap.setCenter([55.758038, 37.572225]);
            } else if (window.matchMedia('(max-width: 1199px)').matches) {
                myMap.setCenter([55.758038, 37.572225]);
            } else {
                myMap.setCenter([55.758038, 37.572225]);
            }
        }

        setCenterMap();
        window.addEventListener("resize", function () {
            setCenterMap();
        });
    }
}
