import Swiper from "swiper";

new Swiper('.news .swiper-container', {
    slideToClickedSlide: true,
    loop: true,
    navigation: {
        nextEl: '.news__next',
        prevEl: '.news__prev',
    },
    slidesPerView: 'auto',
    spaceBetween: 32,
});

new Swiper('.promo-banner .swiper-container', {
    loop: true,
    slidesPerView: 1,
    effect: 'fade',
    autoHeight: true,
    autoplay: {
        delay: 3000,
    },
    pagination: {
        el: '.promo-banner__pagination',
        clickable: true,
    },
    fadeEffect: {
        crossFade: true
    }
});
