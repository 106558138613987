import {event} from "./functions";

event('.search__categories-list>li', 'click', function (e) {
    if (this.classList.contains('active')) {
        this.classList.remove('active');
    } else {
        for (let el of document.querySelectorAll('.search__categories-list>li')) {
            el.classList.remove('active');
        }
        this.classList.add('active');
    }
});
